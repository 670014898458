import Vue from "vue";
import Vuex from "vuex";
import { config } from "vuex-module-decorators";
import signup from "./modules/signup";

// Set raw error mode to true
config.rawError = true;

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    signup,
  },
});

export default store;
