import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./locales/en.json";
import fr from "./locales/fr.json";
import ja from "./locales/ja.json";
import th from "./locales/th.json";
Vue.use(VueI18n);

export const SUPPORTED_LOCALES = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "fr",
    label: "Français",
  },
  {
    value: "ja",
    label: "日本語",
  },
  {
    value: "th",
    label: "ภาษาไทย",
  },
  // {
  //   value: "lo",
  //   label: "ພາສາລາວ"
  // }
];

export default new VueI18n({
  locale: localStorage.getItem("locale") || "en",
  fallbackLocale: "en",
  messages: {
    en,
    fr,
    ja,
    th,
  },
});
