import { Invitation, Organization } from "@/models/invitation";
import { SignupState } from "@/models/signup-state";
import { Subscription } from "@/models/subscription";
import { SubscriptionSetup } from "@/models/subscription-setup";
import SignupApi from "@/services/signup-api";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

const initialState: SignupState = {
  region: "",
  members: [],
  email: "",
  verificationCode: "",
  password: "",
  agreedOnTerms: false,
  duplicateEmail: false,
  confirmed: false,
  completedSteps: 0,
};

@Module({
  name: "signup",
  namespaced: true,
})
export default class SignupStoreModule extends VuexModule {
  data: SignupState = { ...initialState };

  @Mutation
  updateState(newState: Partial<SignupState>): void {
    this.data = {
      ...this.data,
      ...newState,
    };
  }

  @Mutation
  clearState(): void {
    this.data = { ...initialState, members: [] };
  }

  @Mutation
  setCompletedSteps(completedSteps: number): void {
    this.data = {
      ...this.data,
      completedSteps,
    };
  }

  @Action
  saveBasicInfo(info: SignupState): void {
    const { members, email, password } = info;
    this.updateState({
      members,
      email,
      password,
    });
  }

  @Action
  setConfirmedSignup(confirmed: boolean): void {
    this.updateState({ confirmed });
  }

  @Action
  setConfirmationMethod(verificationMethod: string): void {
    this.updateState({ verificationMethod });
  }

  @Action
  async loadOrganizationByInvitationCode(
    { code, region }: {code: string; region: string}
  ): Promise<Organization | undefined> {
    const invitation: Invitation = await SignupApi.getInvitationInfo(
      code,
      region
    );

    if (invitation && invitation.organization) {
      const { organization } = invitation;
      this.updateState({
        organization: organization.name,
        accountId: organization.id,
        region,
      });
      return organization;
    }

    return undefined;
  }

  @Action
  calculatePlan(setup: SubscriptionSetup): Promise<Subscription> {
    return SignupApi.calculatePlan(setup);
  }

  get signupState(): SignupState {
    return this.data;
  }
}
