import SignupStoreModule from "@/store/modules/signup";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { getModule } from "vuex-module-decorators";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/signup/choose-plans",
    name: "choose-plan",
    // component: () => import("../views/choose-plan-page.vue"),
    redirect: "/signup/invite",
  },
  {
    path: "/signup/invite/:region?/:code?",
    name: "invitation",
    component: () => import("../views/invite-page.vue"),
  },
  {
    path: "/signup/complete",
    name: "complete",
    component: () => import("../views/signup-result-page.vue"),
  },
  {
    path: "/signup/:type/:section?",
    name: "signup",
    component: () => import("../views/signup-page.vue"),
    meta: {
      async beforeResolve(to: Route, from: Route, next: NavigationGuardNext) {
        const signupModule: SignupStoreModule = getModule(
          SignupStoreModule,
          store
        );
        const section = Number(to.params.section);
        const type = to.params.type;

        if (type !== "annual" && type !== "custom" && type !== "corporate") {
          next({ name: "404" });
          return;
        }

        if (type === "corporate" && !to.query.code) {
          next({ name: "404" });
          return;
        }

        const completedSteps = signupModule.signupState.completedSteps || 0;
        if (completedSteps + 1 >= section) {
          next();
        } else {
          next({
            name: "signup",
            params: {
              section: `${completedSteps + 1}`,
            },
          });
        }
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404-page.vue"),
  },
  {
    path: "/",
    redirect: "/signup/choose-plans",
  },

  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  /*
   * Create a `beforeResolve` hook, which fires whenever
   * `beforeRouteEnter` and `beforeRouteUpdate` would. This
   * allows us to ensure data is fetched even when params change,
   * but the resolved route does not. We put it in `meta` to
   * indicate that it's a hook we created, rather than part of
   * Vue Router (yet?).
   */
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise(
        (
          resolve: (value: unknown) => void,
          reject: (reason?: any) => void // eslint-disable-line
        ): void => {
          /*
           * If a `beforeResolve` hook is defined, call it with
           * the same arguments as the `beforeEnter` hook.
           */
          if (route.meta && route.meta.beforeResolve) {
            // eslint-disable-next-line
            route.meta.beforeResolve(routeTo, routeFrom, (...args: any[]) => {
              // If the user chose to redirect...
              if (args.length) {
                // Complete the redirect.
                next(...args);
                reject(new Error("Redirected"));
              } else {
                resolve(null);
              }
            });
          } else {
            // Otherwise, continue resolving the route.
            resolve(null);
          }
        }
      );
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

export default router;
