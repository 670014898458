import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./global.scss";

import "@newlogic/nl-components.assistance-app/dist/nl-components/nl-components.css";
import { defineCustomElements } from "@newlogic/nl-components.assistance-app";

defineCustomElements();

Vue.config.productionTip = false;
Vue.prototype.$baseWebsite = "https://aware24.com/";

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
